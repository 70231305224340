import FlexBox from '@/atomic-components/flex-box';
import { Box, Drawer, Divider as MuiDivider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from '@/hooks/useLocation';
import { locationService } from '@/services/location';
import { TGetServiceableCitiesApiResponseData } from '@/services/location/models/get-serviceable-cities';
import { ApiState } from '@/models/generics';
import { getColor } from '@/utils/getColor';
import { getStyle } from '@/utils/getStyle';
import { ICity } from '@/models/location';
import { ICheckPincodeServiceabilityApiResponseData } from '@/services/location/models/check-pincode-serviceability';
import { LOCATION_SELECTOR_TRIGGER_OPTIONS } from '@/store/location/actionTypes';
import { TLocation } from '@/store/location/reducer';
import { getLocationFromLocalStorage } from '@/utils/location';
import { dataLayerPushWithEvent } from '@/utils/callcustomevent';
import { useFullScreenLoader } from '@/hooks/useFullScreenLoader';
import {
	WithLoaderProps,
	withFullScreenLoader,
} from '@/hocs/withFullScreenLoader';
import LencoLoaderComponent from '../lenco-loader';
import TopCities from './TopCities';
import OtherCities from './OtherCities';
import PincodeInput from './PincodeInput';
import { useNotification } from '@/hooks/useNotification';
import { NotificationSeverity } from '../NotifyToast';
import CrossIcon from '@/assets/city/close.svg';
import { useUser } from '@/hooks/useUser';
import usePlan from '@/hooks/unlmtd/usePlan';
import { emitMoeEvent } from '@/utils/events';

export type LocationSelectorProps = WithLoaderProps & {
	// onClose?: (location?: TLocation) => any;
};

enum CitiesListView {
	TOP_CITIES = 'TOP_CITIES',
	OTHER_CITIES = 'OTHER_CITIES',
}

const LocationSelectorDrawer = (props: LocationSelectorProps) => {
	const { selectedLocation, locationActions, isLocationSelectorOpen } =
		useLocation();
	const { loader } = useFullScreenLoader();
	const [pincode, setPincode] = useState<string>('');
	const [locationSelectorViewState, setLocationSelectorViewState] =
		useState<ApiState>(ApiState.LOADING);
	const [checkPincodeViewState, setCheckPincodeViewState] = useState<ApiState>(
		ApiState.IDLE
	);
	const [serviceableCitiesResponse, setServiceableCitiesService] =
		useState<TGetServiceableCitiesApiResponseData>();
	const [pincodeError, setPincodeError] = useState<string | undefined>();
	const [citiesListViewState, setCitiesListViewState] =
		useState<CitiesListView>(CitiesListView.TOP_CITIES);
	const { notifyActions } = useNotification();
	const {planActions:{ removePlan }} = usePlan()
	const { userActions } = useUser();

	const containsOtherCities =
		!!serviceableCitiesResponse?.otherCities &&
		Array.isArray(serviceableCitiesResponse?.otherCities) &&
		serviceableCitiesResponse?.otherCities.length > 0;

	function onPincodeEnter(e: React.ChangeEvent<HTMLInputElement>) {
		setPincodeError(undefined);
		setPincode(e.currentTarget.value);
	}

	function checkIfPincodeValid(pincode: string) {
		const regex = /^[1-9][0-9]{5}$/;

		return regex.exec(pincode);
	}

	async function getEnteredPincodeDetails(): Promise<ICheckPincodeServiceabilityApiResponseData | null> {
		return locationService
			.getPincodeServiceabilityService(Number(pincode))
			.then((res) => {
				setCheckPincodeViewState(ApiState.COMPLETED);
				return res.data?.data;
			})
			.catch((error) => {
				setCheckPincodeViewState(ApiState.FAILED);
				return null;
			});
	}

	async function getServiceableCities() {
		setLocationSelectorViewState(ApiState.LOADING);

		try {
			const res = await locationService.getServiceableCitiesService();

			if (res.data?.success) {
				setServiceableCitiesService(res.data?.data);
				setLocationSelectorViewState(ApiState.SUCCESS);
			}
		} catch (error) {
			console.error(error);
			setLocationSelectorViewState(ApiState.FAILED);
		}

		setLocationSelectorViewState(ApiState.COMPLETED);
	}

	async function checkPincode() {
		loader.show();
		dataLayerPushWithEvent('moe_event', {
			moe_event_name: 'Pincode Entered',
			moe_event_data: {
				pincode: pincode,
			},
		});
		setCheckPincodeViewState(ApiState.LOADING);
		const isPincodeValid = checkIfPincodeValid(pincode.toString());

		if (isPincodeValid) {
			try {
				const pincodeDetails = await getEnteredPincodeDetails();
				if (pincodeDetails?.isServiceable) {
					saveLocationAndGetMeta({
						cityId: pincodeDetails.city.id,
						cityName: pincodeDetails.city.name,
						pincode: Number(pincode),
					});
				} else {
					setPincodeError('Pincode is not serviceable');
					emitMoeEvent('Invalid Pincode Error')
					dataLayerPushWithEvent('moe_event', {
						moe_event_name: 'Unserviceable Area Error',
						moe_event_data: {
							pincode: pincode,
						},
					});
				}
			} catch (error: any) {
				emitMoeEvent('Invalid Pincode Error')
				setPincodeError(
					error?.response?.data?.error?.message ?? 'Something Went Wrong'
				);
				console.error(error);
			}
		} else {
			setPincodeError('Invalid Pincode Entered');
		}

		setCheckPincodeViewState(ApiState.COMPLETED);
		loader.hide();
	}

	function saveLocationAndGetMeta(location: TLocation) {
		notifyActions.notifyUser({
			severity: NotificationSeverity.SUCCESS,
			message: 'Location updated successfully',
		});
		locationActions.updateSelectedLocation(location);

		locationActions.triggerCitySelector(
			LOCATION_SELECTOR_TRIGGER_OPTIONS.LOCATION_SELECTOR_CLOSE
		);
	}

	function onCityClicked(city: ICity) {
		dataLayerPushWithEvent('moe_event', {
			moe_event_name: 'City Selected',
			moe_event_data: {
				selected_city: city.id,
			},
		});
		const location: TLocation = {
			pincode: city.default_pincode,
			cityId: city.id,
			cityName: city.name,
		};
		removePlan();
		setCitiesListViewState(CitiesListView.TOP_CITIES);
		saveLocationAndGetMeta(location);
	}

	const closeLocationSelector = () => {
		locationActions.triggerCitySelector(
			LOCATION_SELECTOR_TRIGGER_OPTIONS.LOCATION_SELECTOR_CLOSE
		);

		if (!selectedLocation.pincode) {
			/*
				if location doesnot exist in localstorage,
				save default location to localstorage
			*/
			locationActions.updateSelectedLocation({
				pincode: 560068,
				cityId: 1,
				cityName: 'Bengaluru',
			});
		}

		setCitiesListViewState(CitiesListView.TOP_CITIES);
	};

	useEffect(() => {
		if (isLocationSelectorOpen) {
			setPincode('');
			dataLayerPushWithEvent('moe_event', {
				moe_event_name: 'City Selection Page Loaded',
				moe_event_data: {
					trackingUrl: window?.location?.href,
				},
			});
		}
	}, [isLocationSelectorOpen]);

	useEffect(()=>{
		userActions.getUser();
	},[])

	useEffect(() => {
		if (isLocationSelectorOpen && !serviceableCitiesResponse) {
			getServiceableCities();
		}
		const {
			pincode: lpincode,
			cityId: lcityId,
			cityName: lcityName,
		} = getLocationFromLocalStorage();

		// cart is calling Unnecessarly click on location selector

		if (!(lpincode && lcityId && lcityName)) {
			//TODO:BOT HANDLING FOR CITY SELECTOR
			// locationActions.triggerCitySelector(
			// 	LOCATION_SELECTOR_TRIGGER_OPTIONS.LOCATION_SELECTOR_OPEN
			// );
		} else {
			// userActions.getUser();
		}
	}, [isLocationSelectorOpen]);

	const TopCity = serviceableCitiesResponse?.topCities || [];
	const OtherCity = serviceableCitiesResponse?.otherCities || [];

	const AllServiceableCities = [...TopCity];

	return (
		<Drawer
			anchor='bottom'
			variant='temporary'
			open={isLocationSelectorOpen}
			onClose={closeLocationSelector}
			disableScrollLock
		>
			<Box sx={{ height: '100vh' }}>
				{locationSelectorViewState == ApiState.LOADING ? (
					<LencoLoaderComponent />
				) : (
					<FlexBox
						direction='column'
						align='start'
						sx={{ background: 'white' }}
					>
						<FlexBox
							justify='spaceBetween'
							sx={{
								width: '100%',
								alignItems: 'center',
								padding: '40px 24px 32px 24px',
							}}
						>
							<Typography
								sx={{
									...getStyle('H11-Medium'),
									color: getColor('Gray 0'),
								}}
							>
								Select Delivery Location
							</Typography>
							<Box onClick={closeLocationSelector}>
								<CrossIcon />
							</Box>
						</FlexBox>
						<PincodeInput />
						<TopCities
							cities={AllServiceableCities}
							onCityClicked={onCityClicked}
						/>
						{OtherCity.length ?<OtherCities
							cities={OtherCity || []}
							onCityClicked={onCityClicked}
						/>:<></>}
					</FlexBox>
				)}
			</Box>
		</Drawer>
	);
};

export default withFullScreenLoader(LocationSelectorDrawer);
